import { Controller } from "@hotwired/stimulus";

export default class LogoutController extends Controller {
  static values = { url: String };
  declare readonly urlValue: string;

  logout(): void {
    // End the Intercom session, so that that the logged out lead doesn't see
    // the conversations of the previously logged in user.
    // check if window.Intercom is defined before calling it
    if (typeof window.Intercom === "function") {
      window.Intercom("shutdown");
    }

    // Navigate to the logout URL.
    window.location.href = this.urlValue;
  }
}
