import { Controller } from "@hotwired/stimulus";

export default class ModalToggleController extends Controller {
  static values = { id: String };
  declare readonly idValue: string;

  trigger(): void {
    this.dispatch("toggle", { detail: { modalId: this.idValue } });
  }
}
