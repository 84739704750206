import "@yaireo/tagify/dist/tagify.css";

export const initTree = (root: Element): void => {
  cleanupTree(root);

  const elements = root.querySelectorAll('textarea[data-tagify="true"]');

  // If there are no elements, abort before loading the library.
  if (elements.length === 0) {
    return;
  }

  renderAll(elements as NodeListOf<HTMLInputElement>).catch((reason) => {
    console.log("Failed to render tagify:", reason);
  });
};

const renderAll = async (
  elements: NodeListOf<HTMLInputElement>,
): Promise<void> => {
  const { default: Tagify } = await import("@yaireo/tagify");

  elements.forEach((el) => {
    const regexPattern = el.getAttribute("data-tagify-regex") ?? "";
    // eslint-disable-next-line no-new
    new Tagify(el, {
      pattern: new RegExp(regexPattern),
    });
  });
};

const cleanupTree = (root: Element): void => {
  // Remove any existing tagify elements.
  //
  // Iterate over the elements in reverse since live HTML collections get
  // shorter when you remove elements.
  const existingTags = root.querySelectorAll("tags.tagify");
  for (let i = existingTags.length - 1; i >= 0; i--) {
    const element = existingTags[i];
    if (element.parentNode === null) continue;
    element.parentNode.removeChild(element);
  }
};
