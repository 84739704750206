import { Controller } from "@hotwired/stimulus";

export default class ModalController extends Controller {
  static values = { id: String, open: { type: Boolean, default: false } };
  declare readonly idValue: string;
  declare openValue: boolean;

  connect(): void {
    this.openValueChanged();
  }

  open(): void {
    this.openValue = true;
  }

  close(): void {
    this.openValue = false;
  }

  toggle(event: CustomEvent | undefined): void {
    // Ignore the event if is is intended for another modal.
    if (event?.detail?.modalId !== this.idValue) return;

    this.openValue = !this.openValue;
  }

  openValueChanged(): void {
    this.element.classList.toggle("hidden", !this.openValue);

    // Disable scrolling on the body when the sidebar is open.
    document.body.style.overflow = this.openValue ? "hidden" : "auto";
  }
}
